*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.App {
  /* fallback in case browser doesn't support gradient/url combination */
  background-image: url('./nav-background.jpg');
  background: 
   linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 59%, rgba(0, 0, 0, 1) 100%),
   url('./nav-background.jpg') no-repeat;
  background-size: 100vw 200px;
  background-repeat: no-repeat;
  background-color: rgb(0,0,0);
  font-family: alegreya, Times, serif;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: white;
}

body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

@font-face {
  font-family: alegreya;
  src: url(./resources/alegreya-variablefont_wght-webfont.woff);
}

.body {
  padding-top: 65px;
  margin-right: 320px;
  margin-left: 10vw;
  min-height: 80vh;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
}