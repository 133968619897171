.navBar1{
    font-weight: bold;
    display: block;
    background-color: rgba(255,255,255,0.07);
    border-style: bevel;
    border-bottom: 2px rgba(255,255,255,0.05) inset;
    position: fixed;
    z-index: 1200;
    width: 100vw;
}

.selectedlink {
    color: 'white';
    width: 'auto';
}