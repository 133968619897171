.signup-container {
    margin-top: 65px;
    background-color: rgba(255,255,255,0.1);
    padding: 6px;
    border-radius: 4px;
    width: 27vw;
    max-width: 300px;
    color: rgba(255,255,255,0.9);
    animation: wiggle 2.5s infinite;
    margin-right: 40px;
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.close-btn {
    cursor: pointer;
}