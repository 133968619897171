.portrait {
  height: 300px;
  width: 300px;
}

.w-60 {
  width: 60%;
}

.about-text {
  background-color: rgba(255,255,255,0.07);
  border-style: bevel;
  border: 2px rgba(255,255,255,0.05) inset;
  border-radius: 2px;
}